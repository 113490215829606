<template>
  <div class="home">
    <img src="@/assets/png/home-bg__mirror-mob.png"
         class="mirror mob" />

    <img src="@/assets/png/home-bg__curtain-mob.png"
         class="curtain mob" />

    <img src="@/assets/png/home-bg__table-mob.png"
         class="table mob" />

    <img src="@/assets/png/home-bg__mirror@1920.png"
         class="mirror large" />

    <img src="@/assets/png/home-bg__curtain@1920.png"
         class="curtain large" />

    <img src="@/assets/png/home-bg__table@1920.png"
         class="table large" />

    <img src="@/assets/png/home-bg__mirror.png"
         class="mirror medium" />

    <img src="@/assets/png/home-bg__curtain.png"
         class="curtain medium" />

    <img src="@/assets/png/home-bg__table.png"
         class="table medium" />


    <div class="block mirror-game"
         v-b-modal.popit-win-modal>
    </div>

    <div class="block popit-game"
         v-b-modal.plug-modal>
    </div>

    <div class="block puzzle-game"
         v-b-modal.prize-modal>
    </div>

    <!-- <PopitPrize /> -->
  </div>
</template>

<script>
import PopitPrize from '@/components/PopitPrize.vue'

export default {
  name: 'Home',
  components: {
    PopitPrize,
  },
}
</script>

<style scoped lang="scss">
img {
  width: 100%;
  height: 100%;
  max-height: 100vh;
}

.home {
  background-image: url('@/assets/png/home-bg@1920.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  max-height: 100vh;
  position: relative;

  @media(max-width: 1500px){
    background-image: url('@/assets/png/home-bg.png');
  }

  @media(max-width: 999px){
    background-image: url('@/assets/png/home-bg-mob.png');
  }

  .block {
    position: absolute;
    z-index: 10;
    cursor: pointer;
    transition: .4s all;

    &:hover {
      opacity: 0.7;
    }
  }

  .mirror-game {
    width: 260px;
    height: 340px;
    right: 0;
    top: 0;
    margin-right: 18%;
    margin-top: 5%;

    @media(max-width: 1500px){
      width: 250px;
      height: 300px;
      margin-right: 20%;
      margin-top: 3%;
    }

    @media(max-width: 1300px){
      width: 200px;
      height: 200px;
      margin-top: 4%;
    }

    @media(max-width: 1100px){
      width: 180px;
      height: 270px;
      margin-top: 5%;
    }

    @media(max-width: 1000px){
      margin-right: 14%;
      margin-top: 5%;
    }

    @media(max-width: 576px){
      width: 210px;
      height: 270px;
      margin-top: 16%;
      margin-right: 38%;
    }

    @media(max-width: 410px){
      width: 180px;
      height: 270px;
      margin-top: 18%;
      margin-right: 36%;
    }
  }

  .popit-game {
    width: 350px;
    height: 200px;
    right: 0;
    bottom: 0;
    margin-right: 14.6%;
    margin-bottom: 15%;

    @media(max-width: 1500px){
      width: 300px;
      height: 270px;
      margin-right: 16%;
      margin-bottom: 13%;
    }

    @media(max-width: 1300px){
      width: 280px;
      height: 130px;
      margin-bottom: 12%;
    }

    @media(max-width: 1100px){
      width: 230px;
      height: 140px;
      margin-bottom: 20%;
    }

    @media(max-width: 576px){
      width: 280px;
      height: 170px;
      margin-bottom: 45%;
      margin-right: 27%;
    }

    @media(max-width: 410px){
      width: 230px;
      height: 150px;
      margin-bottom: 60%;
      margin-right: 26.6%;
    }
  }

  .puzzle-game {
    height: 100vh;
    right: 0;
    width: 200px;

    @media(max-width: 1500px){
      width: 170px;
    }

    @media(max-width: 1300px){
      width: 140px;
    }

    @media(max-width: 1100px){
      width: 120px;
    }

    @media(max-width: 576px){
      width: 70px;
    }

    @media(max-width: 410px){
      width: 50px;
    }
  }

  img {
    position: absolute;
    transition: .4s all;
    z-index: 1;
  }

  .mirror {
    top: 0;

    -webkit-animation: glow 2s linear infinite alternate;
    -moz-animation: glow 2s linear infinite alternate;
    -ms-animation: glow 2s linear infinite alternate;
    -o-animation: glow 2s linear infinite alternate;
    animation: glow 2s linear infinite alternate;
  }

  .curtain {
    top: 0;

    -webkit-animation: glow 2s linear infinite alternate;
    -moz-animation: glow 2s linear infinite alternate;
    -ms-animation: glow 2s linear infinite alternate;
    -o-animation: glow 2s linear infinite alternate;
    animation: glow 2s linear infinite alternate;
  }

  .table {
    bottom: 0;

    -webkit-animation: glow 2s linear infinite alternate;
    -moz-animation: glow 2s linear infinite alternate;
    -ms-animation: glow 2s linear infinite alternate;
    -o-animation: glow 2s linear infinite alternate;
    animation: glow 2s linear infinite alternate;
  }
}

@keyframes glow {
  from {
    filter: contrast(100%) brightness(100%) blur(0px);
    // box-shadow: 0px 1px 13px 5px rgba(89, 236, 22, 0.1);
  }

  to {
    filter: contrast(115%) brightness(125%) blur(.5px);
    // box-shadow: 3px 3px 79px 60px rgba(89, 236, 22, 0.5);
  }
}

.large {
  @media(max-width: 1500px){
    display: none;
  }
}

.medium {
  display: none;

  @media(max-width: 1500px){
    display: block;
  }

  @media(max-width: 999px){
    display: none;
  }
}

.mob {
  display: none;

  @media(max-width: 999px){
    display: block;
  }
}
</style>
