<template>
  <div class="wrapper">
  </div>
</template>

<script>

export default {
  name: 'Popit',
  components: {
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  background-image: url('@/assets/png/popit-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  max-height: 100vh;
  position: relative;
}
</style>
