<template>
	<label class="radio-button" :class="{'radio-button--disabled': opacity}">
		<input type="radio" :checked="value === code" :value="code" @change="change">
		<span class="radio-button__status"></span>
		<p class="radio-button__text">
			{{ text }}
		</p>
	</label>
</template>

<script>
export default {
	name: 'RadioButton',
	props: {
		name: {
			type: String,
			default: '',
		},
		value: {
			type: String,
			default: '',
		},
		code: {
			type: String,
			default: '',
		},
		text: {
			type: String,
			default: '',
		},
		opacity: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		change() {
			this.$emit('input', this.code);
		}
	}
};
</script>

<style scoped lang="scss">
	.radio-button {
		display: flex;
		align-items: center;
		background-color: $white;
		border-radius: 100px;
		padding: 12px 18px;
		cursor: pointer;
    	min-height: 50px;
		margin-bottom: 10px;
		transition: .2s ease;

		input {
			display: none;

			&:checked {
				 & + .radio-button__status {
					background-color: $green;
				}
			}
		}

		&--disabled {
			.radio-button__text {
				color: $grey;
			}
		}

		&:hover {
			box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.05);
		}
	}
	.radio-button__status {
		background-color: $grey;
		height: 18px;
		width: 18px;
    min-width: 18px;
		border-radius: 50%;
		margin-right: 22px;
		transition: .2s ease;
	}
	.radio-button__text {
		margin-bottom: 0;
	}
	@media (max-width: 768px) {
		.radio-button {

		}
    .radio-button__status {
      background-color: $grey;
      height: 14px;
      width: 14px;
      min-width: 14px;
    }
	}
</style>
