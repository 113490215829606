<template>
  <div>
    <b-modal
      id="test-result"
      ref="modal"
      title=""
      hide-footer
      hide-header
      centered
      @hide="redirect"
    >
      <span class="close-icon"
            @click="redirect">
        <img src="@/assets/svg/icons/close.svg"
             aria-label="close">
      </span>

      <div class="d-flex flex-column align-items-center test-result__wr">
        <h1 class="title">
          {{ result.title }}
        </h1>

        <p class="modal-text">
          {{ result.text }}
        </p>

        <router-link class="button button-green" :to="`/psychologist-page#${type}`">
          читать
        </router-link>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { TEST_RESULTS } from '@/consts/test.js';

export default {
	name: 'TestResult',
	props: {
		type: String,
		default: '',
	},
  computed: {
    result() {
      if (this.type) {
        return TEST_RESULTS[this.type];
      } else {
        return { title: '', text: '' };
      }
    }
  },
  methods: {
    close() {
      this.$bvModal.hide('popit-modal');
    },
    redirect() {
      this.$router.push(`/psychologist-page#${this.type}`);
    }
  }
};
</script>

<style scoped lang="scss">
.test-result__wr {
  margin-bottom: 87px;
}
.title {
  font-size: 42px;
  text-align: center;
}

.close-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  margin-top: 5px;
  cursor: pointer;
  transition: .5s all;

  img {
    width: 100%;
  }

  &:hover {
    opacity: .8;
  }
}

.button {
  width: 100%;
  max-width: 120px;
  margin-top: 20px;
}
@media (max-width: 768px) {
  .title {
    font-size: 30px;
    line-height: 28px;
  }

  .test-result__wr {
    margin-bottom: 0;
  }
}
@media (max-width: 425px) {
  .close-icon {
    height: 16px;
    width: 16px;
    margin-right: 5%;
    margin-top: -2px;
  }
}
</style>
