export const PROMO_FAQ_QUESTION = [
  {
  	question: 'В каких городах проводится акция?',
  	answer: 'В каких городах проводится акция?',
  },
  {
  	question: 'Кто является Организатором акции?',
  	answer: '2.1.	Организатор акции (далее – Организатор): Leroy Merlin',
  },
  {
  	question: 'Какие сроки проведения акции?',
  	answer: 'Какие сроки проведения акции?',
  },
  {
  	question: 'Какие призы я могу выиграть?',
  	answer: 'Какие призы я могу выиграть?',
  },
  {
  	question: 'Как я смогу получить призы?',
  	answer: 'Как я смогу получить призы?',
  },
  {
  	question: 'Надо ли платить налоги с приза?',
  	answer: 'Надо ли платить налоги с приза?',
  },
  {
  	question: 'Как я узнаю, что стал победителем?',
  	answer: 'Как я узнаю, что стал победителем?',
  },
];

export const PROMO_GIFTS = [
  {
    name: 'Для Семьи',
    desc: 'Наполнение: Набор корзин, банные полотенца, ароматическая свеча'
  },
  {
    name: 'Для Красоты',
    desc: 'Наполнение: Органайзер, дозатор для жидкого мыла, настольное зеркало'
  },
  {
    name: 'Для уюта',
    desc: 'Наполнение: Ароматический диффузор и наполнитель, шторка для ванной, коврик'
  },
];
