export const TEST_RESULT_TYPES = ['auditory' ,'visual' ,'kinesthetic' ,'digital'];

export const TEST_QUESTIONS = [
	{
		question: 'Чему вы больше поверите?',
		answers: [
			{
				text: 'тому, что увидите своими глазами',
				code: 'visual',
			},
			{
				text: 'фактам',
				code: 'digital',
			},
			{
				text: 'собственным чувствам',
				code: 'kinesthetic',
			},
			{
				text: 'тому, в чем есть для вас смысл',
				code: 'auditory',
			},
		]
	},
	{
		question: 'Чему вы учитесь чаще всего?',
		answers: [
			{
				text: 'понимать какие-то вещи',
				code: 'digital',
			},
			{
				text: 'делать какие-то вещи',
				code: 'kinesthetic',
			},
			{
				text: 'слушать новое',
				code: 'auditory',
			},
			{
				text: 'видеть новые возможности',
				code: 'visual',
			},
		]
	},
	{
		question: 'Как вы принимаете важные решения?',
		answers: [
			{
				text: 'Основываясь на своих ощущениях',
				code: 'kinesthetic',
			},
			{
				text: 'Если будет звучать для меня убедительно',
				code: 'auditory',
			},
			{
				text: 'Опираясь на собственную точку зрения',
				code: 'visual',
			},
			{
				text: 'Опираюсь на факты и логику',
				code: 'digital',
			},
		]
	},
	{
		question: 'Какой человек скорее всего окажет на вас влияние?',
		answers: [
			{
				text: 'У кого приятный голос',
				code: 'auditory',
			},
			{
				text: 'Кто хорошо выглядит',
				code: 'visual',
			},
			{
				text: 'Кто говорит разумные вещи',
				code: 'digital',
			},
			{
				text: 'Кто пробуждает у меня хорошие чувства',
				code: 'kinesthetic',
			},
		]
	},
	{
		question: 'Какое из утверждений подходит вам больше всего?',
		answers: [
			{
				text: 'мне очень легко понять смысл новых фактов и данных',
				code: 'digital',
			},
			{
				text: 'я всегда обращаю внимание на звуки окружающей действительности',
				code: 'auditory',
			},
			{
				text: 'я хорошо чувствую одежду, облегающую мое тело',
				code: 'kinesthetic',
			},
			{
				text: 'я легко замечаю все краски мира',
				code: 'visual',
			},
		]
	},
	{
		question: 'Представляя своего друга, что вы вспомните в первую очередь?',
		answers: [
			{
				text: 'Как он выглядит',
				code: 'visual',
			},
			{
				text: 'Как звучит его голос',
				code: 'auditory',
			},
			{
				text: 'Как он говорит',
				code: 'digital',
			},
			{
				text: 'Свои ощущения по отношению к нему',
				code: 'kinesthetic',
			},
		]
	},
	{
		question: 'Что для вас предпочтительнее?',
		answers: [
			{
				text: 'услышать факты, о которых человек знает',
				code: 'digital',
			},
			{
				text: 'увидеть картины, которые человек нарисовал',
				code: 'visual',
			},
			{
				text: 'узнать о чувствах человека',
				code: 'kinesthetic',
			},
			{
				text: 'послушать звучание голоса, звуки происходящих событий',
				code: 'auditory',
			},
		]
	},
	{
		question: 'Как окружающие смогут узнать о ваших делах?',
		answers: [
			{
				text: 'по моим чувствам',
				code: 'kinesthetic',
			},
			{
				text: 'по моему внешнему виду',
				code: 'visual',
			},
			{
				text: 'им следует послушать, как я говорю',
				code: 'digital',
			},
			{
				text: 'по интонациям моего голоса',
				code: 'auditory',
			},
		]
	},
	{
		question: 'Какое утверждение о вас наиболее верно?',
		answers: [
			{
				text: 'обычно я хорошо чувствую настроения людей',
				code: 'kinesthetic',
			},
			{
				text: 'я могу нарисовать лица, различные подробности того, что я видел',
				code: 'visual',
			},
			{
				text: 'я знаю, что именно думают близкие люди по поводу тех или иных вопросов',
				code: 'digital',
			},
			{
				text: 'я хорошо различаю интонацию голосов моих друзей',
				code: 'auditory',
			},
		]
	},
	{
		question: 'Что вам легче всего сделать из нижеперечисленного?',
		answers: [
			{
				text: 'найти идеальную для себя громкость звучания магнитофона, телевизора',
				code: 'auditory',
			},
			{
				text: 'подобрать наиболее разумные, убедительные аргументы, относящиеся к интересующему вас предмету',
				code: 'digital',
			},
			{
				text: 'выбрать наиболее удобную мебель',
				code: 'kinesthetic',
			},
			{
				text: 'подобрать для одежды, интерьера и др. богатые цветовые комбинации',
				code: 'visual',
			},
		]
	},
];

export const TEST_RESULTS = {
	'auditory': {
		title: 'У вас аудиальный тип репрезентативной системы',
		text: 'Смотрите рекомендации по дизайну ванной комнаты от психолога для вашего типа',
	},
	'visual': {
		title: 'У вас визуальный тип репрезентативной системы',
		text: 'Смотрите рекомендации по дизайну ванной комнаты от психолога для вашего типа'
	},
	'kinesthetic': {
		title: 'У вас кинестетический тип репрезентативной системы',
		text: 'Смотрите рекомендации по дизайну ванной комнаты от психолога для вашего типа',
	},
	'digital': {
		title: 'У вас дигитальный тип репрезентативной системы',
		text: 'Смотрите рекомендации по дизайну ванной комнаты от психолога для вашего типа',
	},
};

export const RECOMMENDATIONS_BY_TYPE = {
	visual: {
		title: 'ВИЗУАЛ',
		text: 'Визуал воспринимает мир глазами. Для него очень важно, как «это» выглядит. И неважно, о чем идет речь, о собственном внешнем виде, об интерьере помещения, в котором он находится, или сервировке стола. Главное, чтобы он воспринимал объект как красивый и гармоничный лично для него. Легким неудобством, таким как некомфортные туфли на высоком каблуке, жесткое кресло или тяжелые приборы он может легко пренебречь. Легкое неудобство не станет препятствием для удовольствия, визуал готов идти на такие «жертвы».',
		slides: [
			{
				img: 'visual-1',
				title: 'Элементы природы в ванной (Коллекция Скандинавский стиль)',
				text: 'Сочетание плитки с узором травы и приглушенного зеленого цвета успокоит и расслабит человека, любящего наблюдать за природой. Общий пастельный тон и природные мотивы приятны глазу. А значит, визуал ощутит прилив приятных эмоций',
			},
			{
				img: 'visual-2',
				title: 'Ванна в стиле Мемфис (Скандинавская коллекция)',
				text: 'Ярко белый цвет сантехники и тумбы под раковиной подарит ощущение свежести и чистоты.',
			},
			{
				img: 'visual-3',
				title: 'Яркие контрасты (Дизайнерский стиль)',
				text: 'Завораживающий глубиной изумрудный цвет подчеркивает строгую геометрию зеркала и душевой кабины. Визуал воспримет интерьер как живописное полотно, которым можно любоваться.',
			},
		]
	},
	kinesthetic: {
		title: 'КИНЕСТЕТИК',
		text: 'Для кинестетика самое главное это удобство. Телесные ощущения комфорта и легкости вызывают приятные эмоции, ощущения удовлетворения и расслабленности. Если цвет кресла не отвечает его представлениям о прекрасном, но оно принимает его тело нежно и мягко, кинестетик вполне счастлив. Его удовольствие и радость в тактильности. Дотрагивается ли он до теплого отполированного дерева или приятно шершавой керамики, если прикосновение ему приятно, то внешний вид вторичен.',
		slides: [
			{
				img: 'kinesthetic-1',
				title: 'SPA у Вас дома (Скандинавская коллекция)',
				text: 'Это мечта кинестетика! Возможность порадовать свое тело, расслабиться, получить эффект SPA у себя дома не может не порадовать. Наличие двух рассекателей воды в душе, угловая полка, удобно размещенная рядом с душем. Протяни руку, и хоть с закрытыми глазами, найдешь нужный флакон.',
			},
			{
				img: 'kinesthetic-2',
				title: 'Лаконичная ванна (Скандинавский стиль)',
				text: 'Наличие «деревянной панели» создает ощущение тепла и уюта.',
			},
			{
				img: 'kinesthetic-3',
				title: 'Текстура дерева в ванной (Природный стиль)',
				text: 'Для ощущений кинестетика здесь раздолье: тепло деревянной текстуры, к которой хочется прикоснуться, ощущение близости природы, отдыха.',
			},
		]
	},
	auditory: {
		title: 'АУДИАЛ',
		text: 'Для аудиала важен ритм и гармония. Именно аудиал скажет Вам, восхищаясь живописью: «Как звучат здесь краски! Какой удивительный ритм у этой графики!». Ему важно, как это «звучит» и отражается в его внутреннем мире. Не спешите обижаться на человека, если он поворачивается к вам в профиль, когда вы говорите о чем-то важном. Он подставляет вам самый значимый для него орган восприятия - ухо. Он вас слушает! В тоне и интонациях для него сконцентрирован смысл того, о чем вы рассказываете. Не та интонация или ритм, и он чувствует дискомфорт.',
		slides: [
			{
				img: 'auditory-1',
				title: 'Геометрическое настроение. (Скандинавский стиль)',
				text: 'Плитка с геометрическим узором задает определенный ритм. Свободное пространство между геометрическими фигурами позволяет аудиалу «слышать свою мелодию».',
			},
			{
				img: 'auditory-2',
				title: 'Изысканная простота (Дизайнервский стиль)',
				text: 'Легкость и воздушность интерьера ванной комнаты, без перегруженности деталями без резких контрастов позволит расслабиться, ощутить себя дома, в своем мире.',
			},
			{
				img: 'auditory-3',
				title: 'Цвета Тиффани. (Шарм)',
				text: 'Спокойный, ровный тон в интерьере позволяет услышать себя, свои мысли и желания. Спокойно расслабиться, включить воду, прислушаться к ее шуму, закрыть глаза – вот, что сделает аудиал в ванной комнате в изящных спокойных тонах. И будет вполне счастлив от возможности отдохнуть.',
			},
		]
	},
	digital: {
		title: 'ДИГИТАЛ',
		text: 'Люди с дигитальным восприятием мира довольно редки. Это строгие логики, воспринимающие мир через причинно-следственные связи. Вы никогда не убедите логика в своей правоте, если не выстроите логическую цепочку. Это зеркало будет висеть здесь, потому что правильно взглянуть на себя перед выходом из дома в последний момент. Этот аргумент будет принят. Но не начинайте с логиком разговор о акценте в интерьере, он будет сбит с толку.',
		slides: [
			{
				img: 'digital-1',
				title: 'Japandi (Скандинавский стиль)',
				text: 'Изысканная простота и функциональность полностью отвечают запросу человека-логика. Гармонично влияют на его эмоции, внушают спокойствие и чувство порядка.',
			},
			{
				img: 'digital-2',
				title: 'Зонирование с помощью плитки.(Дизайнерский стиль)',
				text: 'Логически выверенное и строго организованное пространство сообщает: «Все хорошо, все правильно, все на своих местах!»  Это действует успокаивающе, позволяет убрать лишнюю тревогу, снижает уровень стресса.',
			},
			{
				img: 'digital-3',
				title: 'Ванная в коричневых тонах (Неоклассика)',
				text: 'Ясность без контрастов и резких переходов цвета воспринимается как продуманное и безопасное пространство. Само ощущение легкого перехода тонов одной гаммы создает ощущение покоя и удовлетворения.',
			},
		]
	},
};
