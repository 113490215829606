<template>
  <div>
    <b-modal
      id="thanks-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      centered
    >
      <span class="close-icon"
            @click="$bvModal.hide('thanks-modal')">
        <img src="@/assets/svg/icons/close.svg"
             aria-label="close">
      </span>

      <div class="d-flex flex-column align-items-center">
        <h1 class="title">Мы все записали, спасибо!</h1>

        <p class="modal-text">
          В ближайшее время наши специалисты <br> свяжутся с вами по доставке призов.
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
  export default {
    data() {
      return {
      }
    },
  }
</script>

<style scoped lang="scss">
.title {
  font-size: 42px;
  text-align: center;
  margin-top: 20px;
}

.close-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  margin-top: 5px;
  cursor: pointer;
  transition: .5s all;

  &:hover {
    opacity: .8;
  }
}

.modal-text {
  font-size: 24px;
  font-weight: 500 !important;
}
</style>
