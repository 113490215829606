<template>
  <div id="app">
    <Sidebar />

    <router-view/>

    <Plug />

    <Popit />

    <Thanks />

    <GetPrize />

    <PopitWin />
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
import Plug from '@/components/modals/Plug.vue'
import Popit from '@/components/modals/Popit.vue'
import Thanks from '@/components/modals/Thanks.vue'
import GetPrize from '@/components/modals/GetPrize.vue'
import PopitWin from '@/components/modals/PopitWin.vue'

export default {
  components: {
    Plug,
    Popit,
    Thanks,
    Sidebar,
    GetPrize,
    PopitWin
  }
}
</script>

<style lang="scss">
</style>
