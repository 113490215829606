<template>
  <header class=""
          :class="{ 'active-menu': mobileMenuOpened }">
    <div class="header-wrapper w-100">
      <router-link :to="{ path: '/' }"
                   class="header-link">
        <img src="@/assets/svg/logo.svg"
             class="logo"
             alt="leroy logo" />
      </router-link>

      <div class="logo-text">
        Ваше антистресс <br> пространство
      </div>

      <div class="section-links desktop">
        <router-link :to="{ path: '/' }"
                     class="section-title">
          мини-игры
        </router-link>

        <router-link class="header-link"
                     to="popit">
          Интерьерный поп-ит
        </router-link>

        <a  v-b-modal.plug-modal
            class="header-link">
          Арт-зеркало
        </a>

        <a v-b-modal.plug-modal
           class="header-link">
          Декоративный пазл
        </a>
      </div>

      <div class="section-links desktop">
        <router-link :to="{ path: '/promo' }"
                     class="section-title">
          розыгрыш призов
        </router-link>

        <router-link :to="{ name: 'promo', hash: '#mechanics' }"
                     @click.native="navigateTo('mechanics')"
                     v-scroll-to="'#mechanics'"
                     class="header-link">
          Механика и призы
        </router-link>

        <a href="/rules.pdf"
           target="_blank"
           class="header-link">
          Правила
        </a>

        <router-link :to="{ name: 'promo', hash: '#winners' }"
                     @click.native="navigateTo('winners')"
                     v-scroll-to="'#winners'"
                     class="header-link">
          Победители
        </router-link>

        <router-link :to="{ name: 'promo', hash: '#faq' }"
                     @click.native="navigateTo('faq')"
                     v-scroll-to="'#faq'"
                     class="header-link">
          Вопросы-ответы
        </router-link>
      </div>

      <div class="section-links desktop">
        <router-link :to="{ path: '/psychologist-page' }"
                     class="section-title">
          мнение психолога
        </router-link>

        <router-link :to="{ path: '/psychologist-page' }"
                     class="header-link">
          Статья
        </router-link>

        <router-link :to="{ path: '/test' }"
                     class="header-link">
          Пройти тест
        </router-link>

        <router-link :to="{ name: 'psychologist-page', hash: '#recommendations' }"
                     @click.native="navigateTo('recommendations')"
                     v-scroll-to="'#recommendations'"
                     class="header-link">
          Рекомендации
        </router-link>
      </div>

      <div class="d-flex align-items-center">
        <a target="_blank"
           href="https://leroymerlin.ru/uslugi/proektirovanie-i-dizayn/sozdayte-vannuyu-komnatu-svoey-mechty/"
           class="button button-green">
          <p>спроектировать свою <br> ванную комнату<p>

          <p>></p>
        </a>

        <div class="">
          <div class="mobile-icon"
               @click="mobileMenuOpened = !mobileMenuOpened">
            <img src="@/assets/svg/icons/burger-opened.svg"
                 v-if="mobileMenuOpened" />

            <img src="@/assets/svg/icons/burger.svg"
                 v-if="!mobileMenuOpened" />
          </div>
        </div>
      </div>
    </div>

    <div class="mobile-menu"
         :class="{ active: mobileMenuOpened }">
         <div class="section-links">
           <router-link :to="{ path: '/' }"
                        class="section-title"
                        @click.native="mobileMenuOpened = false">
             мини-игры
           </router-link>

           <router-link class="header-link"
                        to="popit"
                        @click.native="mobileMenuOpened = false">
             Интерьерный поп-ит
           </router-link>

           <a v-b-modal.plug-modal
              class="header-link"
              @click="mobileMenuOpened = false">
             Декоративный пазл
           </a>

           <a  v-b-modal.plug-modal
               class="header-link"
               @click="mobileMenuOpened = false">
             Арт-зеркало
           </a>
         </div>

         <div class="section-links">
           <router-link :to="{ path: '/promo' }"
                        class="section-title"
                        @click.native="mobileMenuOpened = false">
             розыгрыш призов
           </router-link>

           <router-link :to="{ name: 'promo', hash: '#mechanics' }"
                        @click.native="navigateTo('mechanics', true)"
                        class="header-link">
             Механика и призы
           </router-link>

           <a href="/rules.pdf"
              target="_blank"
              class="header-link"
              @click="mobileMenuOpened = false">
             Правила
           </a>

           <router-link :to="{ name: 'promo', hash: '#winners' }"
                        @click.native="navigateTo('winners', true)"
                        v-scroll-to="'#winners'"
                        class="header-link">
             Победители
           </router-link>

           <router-link :to="{ name: 'promo', hash: '#faq' }"
                        @click.native="navigateTo('faq', true)"
                        v-scroll-to="'#faq'"
                        class="header-link">
             Вопросы-ответы
           </router-link>
         </div>

         <div class="section-links">
           <router-link :to="{ path: '/psychologist-page' }"
                        class="section-title">
             мнение психолога
           </router-link>

           <router-link :to="{ path: '/psychologist-page' }"
                        class="header-link"
                        @click.native="mobileMenuOpened = false">
             Статья
           </router-link>

           <router-link :to="{ path: '/test' }"
                        class="header-link"
                        @click.native="mobileMenuOpened = false">
             Пройти тест
           </router-link>

           <router-link :to="{ name: 'psychologist-page', hash: '#recommendations' }"
                        @click.native="navigateTo('recommendations', true)"
                        v-scroll-to="'#recommendations'"
                        class="header-link">
             Рекомендации
           </router-link>
         </div>
    </div>
  </header>
</template>

<script>

export default {
  name: 'Sidebar',

  data: () => ({
    mobileMenuOpened: false,
  }),

  methods: {
    navigateTo(el, mobile = false) {
      let ele = document.getElementById(el)

      ele.scrollIntoView({behavior: "smooth", block: 'center'});

      if (mobile) {
        this.mobileMenuOpened = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
header {
  min-height: 100vh;
  height: 100vh;
  width: fit-content;
  max-width: 440px;
  position: fixed;
  top: 0;
  min-width: 248px;
  z-index: 991;
  width: 100%;
  background-color: transparent;
  background-position: top left;
  background-repeat: no-repeat;
  background-image: url('@/assets/svg/backgrounds/menu.svg');
  background-size: cover;
  padding-top: 55px;
  padding-left: 50px;

  @media (min-width: 999px) and (max-height: 800px){
    height: 100vh;
    max-width: 270px !important;
    padding-top: 30px;
    padding-left: 40px;
    background-position: top right;
    padding-top: 20px !important;
    padding-left: 20px !important;
    min-width: 248px;
  }

  @media(max-width: 1440px){
    height: 100vh;
    max-width: 335px;
    padding-top: 30px;
    padding-left: 40px;
    background-position: top left;
  }

  @media(max-width: 999px){
    max-height: 200px;
    padding-top: 0;
    height: 100%;
    min-height: 50px;
    min-width: 100vw;
    padding-left: 0;
    background-position: top;
    background-size: 100% 100%;
    background-image: url('@/assets/svg/backgrounds/menu-mob.svg');
  }

  @media(max-width: 768px){
    max-height: 190px;
  }

  @media(max-width: 576px){
    max-height: 150px;
  }

  @media(max-width: 420px){
    max-height: 100px;
  }
}

.header-wrapper {
  @media(max-width: 999px){
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 90px;
    padding-top: 10px;
  }

  @media(max-width: 768px){
    padding-right: 30px;
  }

  @media(max-width: 500px){
    padding-right: 10px;
    padding-top: 5px;
  }
}

.logo {
  margin-left: -38px;
  transition: .6s all;

  @media (min-width: 999px) and (max-height: 800px){
    margin-left: -34px;
    max-width: 180px;
  }

  &:hover {
    opacity: 0.85;
  }

  @media(max-width: 999px){
    margin-left: 0;
  }

  @media(max-width: 768px){
    max-width: 120px;
  }

  @media(max-width: 500px){
    max-width: 100px;
  }
}

.logo-text {
  font-weight: 700;
  color: $dark-text;
  line-height: 110%;
  width: fit-content;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;
  font-size: 24px;

  @media (min-width: 999px) and (max-height: 800px){
    font-size: 20px;
    margin-bottom: 20px !important;
  }

  @media (min-width: 999px) and (max-height: 640px){
    font-size: 18px;
  }

  @media (min-width: 999px) and (max-height: 640px){
    font-size: 18px;
  }

  @media(max-width: 999px){
    display: none;
  }
}

.section-links {
  width: fit-content;
  margin-bottom: 20px;

  @media (min-width: 999px) and (max-height: 800px){
    margin-bottom: 15px !important;
  }

  .section-title {
    color: $green;
    font-weight: 700;
    font-size: 22px;
    line-height: 130%;
    text-transform: uppercase;

    @media (min-width: 999px) and (max-height: 800px){
      font-size: 20px;
    }

    @media (min-width: 999px) and (max-height: 640px){
      font-size: 16px;
    }

    @media(max-width: 999px){
      font-size: 26px;
      line-height: 28px;
    }

    &.router-link-exact-active {
      text-decoration: underline;
    }
  }
}

.header-link {
  cursor: pointer;
  font-weight: 400 !important;
  color: $dark-text;
  text-decoration: none;
  font-size: 20px;
  line-height: 32px;
  transition: .4s all ease;
  font-family: $cera;

  @media (min-width: 999px) and (max-height: 800px){
    font-size: 18px;
    line-height: 26px;
  }

  @media (min-width: 999px) and (max-height: 640px){
    font-size: 16px;
    line-height: 22px;
  }

  &:hover {
    color: $green;
  }
}

.button {
  margin-left: 0;
  margin-top: 100px;

  @media(max-width: 1440px){
    margin-left: -25px;
    padding: 20px 20px;
    margin-top: 0;
  }

  @media (min-width: 999px) and (max-height: 800px){
    font-size: 16px;
    padding: 20px 13px;
    margin-top: 0;
    margin-left: 0px;
  }

  @media (min-width: 999px) and (max-height: 640px){
    margin-left: -10px;
  }

  @media(max-width: 999px){
    margin-left: 0;
    padding: 10px 20px;
  }

  @media(max-width: 375px){
    padding: 10px 10px;
  }
}

.mobile-icon {
  transition: 1s all;
  margin-left: 30px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: none;
  align-items: center;
  justify-content: center;

  @media(max-width: 999px){
    display: flex;
  }


  @media(max-width: 500px){
    margin-left: 20px;
    margin-top: -15px;
  }

  @media(max-width: 350px){
    margin-left: 5px;
  }

  &:hover {
    opacity: 0.9;
  }

  img {
    margin: 0;
    transition: 1s all;
  }
}

.mobile-menu {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 40px;
  padding-top: 30px;
  padding-bottom: 100px;
  position: absolute;
  width: 100vw;
  margin-top: -800px;
  transition: 0s all !important;
  z-index: 992;
  background-color: transparent;

  @media(max-width: 999px){
    display: flex;
  }

  @media(max-width: 375px){
    padding-top: 15px;
  }

  &.active {
    margin-top: 0px;
    transition: .8s all !important;
  }
}

a {
  display: block;
  width: fit-content;
}

p {
  margin-bottom: 0;
}

.desktop {
  @media(max-width: 999px){
    display: none;
  }
}

.active-menu {
  max-height: 750px;
  transition: .7s all;
  background-position: center bottom;
  background-size: 100%;
  background-image: url('@/assets/svg/backgrounds/mobile-menu.svg');
  z-index: 900;

  @media(max-width: 500px){
    max-height: 650px;
  }

  @media(max-width: 375px){
    max-height: 600px;
  }

  @media(max-width: 340px){
    max-height: 560px;
  }
}

.router-link-exact-active {
  color: $green;
}
</style>
