<template>
  <div class="home">
  </div>
</template>

<script>

export default {
  name: 'Mirror',
  components: {
  },
}
</script>

<style scoped lang="scss">
.home {
  background-image: url('@/assets/png/home-bg@1920.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  max-height: 100vh;
  position: relative;

  @media(max-width: 1500px){
    background-image: url('@/assets/png/home-bg.png');
  }

  @media(max-width: 999px){
    background-image: url('@/assets/png/home-bg-mob.png');
  }
}
</style>
