<template>
  <div>
    <b-modal
      id="prize-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      centered
    >
      <span class="close-icon"
            @click="closeModal">
        <img src="@/assets/svg/icons/close.svg"
             aria-label="close">
      </span>

      <div class="d-flex flex-column align-items-center">
        <h1 class="title">ваш подарок уже ждет вас!</h1>

        <p class="text">
          Заполните данные, чтобы получить его
        </p>

        <form ref="form"
              class="modal-form"
              @submit.stop.prevent="submit">
         <div class="inputs">
            <b-form-group
              id="surname-input"
              label-for="name-input"
              :invalid-feedback="invalidName"
            >
              <b-form-input
                id="name-input"
                placeholder="Фамилия Имя Отчество"
                required
                type="text"
                pattern="[A-Za-z]+"
                :class="{ 'is-invalid' : isInvalidName }"
                v-model="$v.item.name.$model"
                :state="validateState($v.item.name)"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label-for="phone-input"
              :invalid-feedback="invalidPhone"
              v-mask="'+7(###)-###-##-##'"
            >
              <b-form-input
                id="phone-input"
                placeholder="Мобильный телефон без +7"
                required
                v-model="$v.item.phone.$model"
                :class="{ 'is-invalid' : isInvalidPhone, 'is-valid' : !isInvalidPhone  }"
                :state="validateState($v.item.phone)"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label-for="email-input"
              :invalid-feedback="invalidEmail"
            >
              <b-form-input
                id="email-input"
                placeholder="E-mail"
                type="email"
                required
                v-model="$v.item.email.$model"
                :class="{ 'is-invalid' : isInvalidEmail, 'is-valid' : !isInvalidEmail  }"
                :state="validateState($v.item.email)"
              ></b-form-input>
            </b-form-group>
         </div>

         <h3>Выберите тип приза:</h3>

         <div class="prizes">
           <div class="prize-wrapper"
                ref="familyBox"
                @click="setPrize($event, 'familyBox')">
             <img src="@/assets/svg/prize.svg" />

             <div>
               <div class="prize-title">
                 Набор "Для Семьи"
               </div>

               <div class="prize-description">
                 Наполнение: Набор корзин, банные полотенца, ароматическая свеча
               </div>
             </div>
           </div>

           <div class="prize-wrapper"
                ref="beautyBox"
                @click="setPrize($event, 'beautyBox')">
             <img src="@/assets/svg/prize.svg" />

             <div>
               <div class="prize-title">
                 Набор "Для Красоты"
               </div>

               <div class="prize-description">
                 Наполнение: Органайзер, дозатор для жидкого мыла, настольное зеркало
               </div>
             </div>
           </div>

           <div class="prize-wrapper"
                ref="homeBox"
                @click="setPrize($event, 'homeBox')">
             <img src="@/assets/svg/prize.svg" />

             <div>
               <div class="prize-title">
                 Набор "Для уюта"
               </div>

               <div class="prize-description">
                 Наполнение: Ароматический диффузор и наполнитель, шторка для ванной, коврик
               </div>
             </div>
           </div>
         </div>

         <b-form-checkbox
           id="checkbox-rules"
           class="mt-3"
           v-model="$v.item.rules.$model"
           :state="validateState($v.item.rules)"
           name="rules"
         >
           Я ознакомился(-лась) с
           <a href="#">Правилами Акции</a>
           и соглашаюсь на передачу персональных данных.
         </b-form-checkbox>
        </form>

        <button class="button button-white"
                  type="submit"
                  :disabled="disabledBtn"
                  @click="submit">отправить
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { validationMixin } from "vuelidate"
import { required, requiredIf, minLength, maxLength, or, helpers } from "vuelidate/lib/validators"

const text = helpers.regex('text', /^[?!,.а-яА-ЯёЁ\s]+$/)
const email = helpers.regex('email', /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)

  export default {
    mixins: [validationMixin],

    validations: {
      item: {
        name: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(40),
          text,
        },
        phone: {
          required,
          minLength: minLength(17),
          maxLength: maxLength(17)
        },
        email: {
          required,
          minLength: minLength(4),
          email
        },
        rules: {
          required
        },
        prize: {
          required
        }
      }
    },

    data() {
      return {
        item: {
          name: '',
          phone: '',
          email: '',
          rules: '',
          prize: ''
        },
        isInvalidName: false,
        isInvalidEmail: false,
        isInvalidPhone: false,
        invalidName: 'Некорректное поле',
        invalidPhone: 'Некорректное поле',
        invalidEmail: 'Некорректное поле',
      }
    },

    computed: {
      disabledBtn(){
        if (this.$v.item.$anyError)
          return true
      }
    },

    methods: {
      resetModal() {
        this.$bvModal.hide('prize-modal')
        this.$bvModal.show('thanks-modal')

        this.$v.$reset()

        this.item.name = '',
        this.item.email = ''
        this.item.phone = '',
        this.item.rules = '',
        this.isInvalidName = false,
        this.isInvalidPhone = false,
        this.isInvalidEmail = false,
        this.invalidName = 'Некорректное поле',
        this.invalidPhone = 'Некорректное поле',
        this.invalidEmail = 'Некорректное поле'
      },

      closeModal(){
        this.$v.$reset()

        this.item.name = '',
        this.item.email = ''
        this.item.phone = '',
        this.item.rules = '',
        this.isInvalidName = false,
        this.isInvalidPhone = false,
        this.isInvalidEmail = false,
        this.invalidName = 'Некорректное поле',
        this.invalidPhone = 'Некорректное поле',
        this.invalidEmail = 'Некорректное поле'

        this.$bvModal.hide('prize-modal')
      },

      setPrize($event, refKey){
        this.$refs['familyBox'].classList.remove('active-prize')
        this.$refs['beautyBox'].classList.remove('active-prize')
        this.$refs['homeBox'].classList.remove('active-prize')

        this.item.prize = refKey
        this.$refs[refKey].classList.add('active-prize')
      },

      validateState(item) {
        const { $dirty, $error } = item
        return $dirty ? !$error : null
      },

      submit() {
        this.$v.item.$touch()

        if (this.$v.item.$anyError)
          return false

        axios.post('', {
        }, {
          headers: {
            'Accept': "application/json",
            'Content-Type': "application/json",
            'access-control-allow-origin': "*"
          }
        }).then((response) => {
          this.resetModal()
        }).catch((error) => {
        })
      },
    }
  }
</script>

<style scoped lang="scss">
.title {
  font-size: 36px;
  color: $white;
  text-align: center;
  margin-bottom: 0;
  margin-top: 60px;

  @media(max-width: 576px){
    font-size: 26px;
    margin-top: 80px;
  }
}

.text {
  font-family: $regular;
  color: $white;
  font-size: 21px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 0;
  text-align: center;

  @media(max-width: 576px){
    font-size: 18px;
  }
}

.close-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  margin-top: 5px;
  cursor: pointer;
  transition: .5s all;

  &:hover {
    opacity: .8;
  }
}

h3 {
  font-weight: 700;
  font-family: $regular;
  color: $white;
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 32px;
  line-height: 28px;
}

.button {
  margin-top: 15px;
}

.prizes {
  width: 100%;

  .prize-wrapper {
    width: 100%;
    background-color: $white;
    border-radius: 30px;
    display: flex;
    min-height: 100px;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
    font-family: $regular;
    cursor: pointer;
    transition: .6s all;

    &:hover {
      opacity: .9;

      .prize-title {
        color: $green;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    @media(max-width: 576px){
      min-height: 130px;
    }
  }

  .prize-title {
    text-transform: uppercase;
    font-size: 18px;
    color: $dark-text;
    font-weight: 700;
    line-height: 21px;
    transition: .6s all;
  }

  .prize-description {
    font-size: 14px;
    font-weight: 400;
    max-width: 90%;
  }

  img {
    max-width: 110px;
    margin-left: -20px;
    margin-top: -30px;
    margin-right: 10px;

    @media(max-width: 576px){
      max-width: 90px;
      margin-left: 0;
      margin-top: 0;
    }
  }
}

.active-prize {
  .prize-title {
    color: $green;
  }
}
</style>
