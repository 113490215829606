import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Promo from '../views/Promo.vue'
import Popit from '../views/Popit.vue'
import Mirror from '../views/Mirror.vue'
import Puzzles from '../views/Puzzles.vue'
import PsychologistOpinion from '../views/PsychologistOpinion.vue'
import Test from '../views/Test.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/promo',
    name: 'promo',
    component: Promo
  },
  {
    path: '/popit',
    name: 'popit',
    component: Popit
  },
  {
    path: '/mirror',
    name: 'mirror',
    component: Mirror
  },
  {
    path: '/puzzles',
    name: 'puzzles',
    component: Puzzles
  },
  {
    path: '/psychologist-page',
    name: 'psychologist-page',
    component: PsychologistOpinion
  },
  {
    path: '/test',
    name: 'test',
    component: Test
  },
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: function(to, from, savedPosition) {
    if (to.hash) {
      return {selector: to.hash}
    } else {
        return { x: 0, y: 0 }
    }
  },
  base: process.env.BASE_URL,
  routes
})

export default router
