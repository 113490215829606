<template>
  <div class="plug">
    <b-modal
      id="plug-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      centered
    >
      <span class="close-icon"
            @click="$bvModal.hide('plug-modal')">
        <img src="@/assets/svg/icons/close.svg"
             aria-label="close">
      </span>

      <div>
        <h1 class="title">Игры еще нет на сайте</h1>

        <p>
          Мы скоро запустим эту игру, а пока вы можете <br>
          сыграть в поп-ит и познакомиться с другими <br>
          разделами нашего сайта.
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
  export default {
    data() {
      return {
      }
    },
  }
</script>

<style scoped lang="scss">
p {
  font-size: 21px;
  line-height: 28px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 0;
  margin-top: 30px;

  @media(max-width: 768px){
    font-size: 18px;
    line-height: 22px;
  }

  @media(max-width: 576px){
    font-size: 16px;
    line-height: 18px;

    br {
      display: none;
    }
  }
}

.title {
  font-size: 32px;
  text-align: center;

  @media(max-width: 576px){
    font-size: 26px;
    margin-top: 40px;
  }
}

.close-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  margin-top: 5px;
  cursor: pointer;
  transition: .5s all;

  &:hover {
    opacity: .8;
  }
}
</style>
