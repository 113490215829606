<template>
  <div class="content-container">
    <div class="content">
    	<div class="test">
    		<h1 class="title">
    			Тест на репрезентативные системы
    		</h1>
        <div class="test__steps">
          <p>
            <span>{{ getFormattedStep }}</span>
            из {{ questions.length }}
          </p>
        </div>
          <div class="test__wr">
            <h2 class="test__question">
              {{ questions[step].question }}
            </h2>
            <transition name="fade">
              <div class="test__questions" v-if="showQuestions">
                <RadioButton
                  v-for="question, i in questions[step].answers"
                  :key="i"
                  :text="question.text"
                  :name="'question'"
                  :code="question.code"
                  :opacity="!!questions[step].value && questions[step].value !== question.code"
                  v-model="questions[step].value"
                />
              </div>
            </transition>
            <button
              v-if="step + 1 < questions.length"
              class="button button-green ml-auto"
              :disabled="!questions[step].value"
              @click="goNext"
            >
              следующий вопрос
            </button>
            <button
              v-else
              class="button button-green ml-auto"
              :disabled="!questions[step].value"
              @click="finish"
            >
              завершить тест
            </button>
          </div>
    	</div>
    </div>
    <TestResult :type="type" />
  </div>
</template>

<script>
import RadioButton from '@/components/RadioButton.vue';
import { TEST_QUESTIONS, TEST_RESULT_TYPES } from '@/consts/test.js';
import TestResult from '@/components/modals/TestResult.vue';

export default {
  name: 'Test',
  components: {
    RadioButton,
    TestResult
  },
  data() {
  	return {
  		questions: [],
  		step: 0,
      showQuestions: true,
      type: ''
  	};
  },
  computed: {
    getFormattedStep() {
      return `${this.step + 1}`.padStart(2, '0');
    },
  },
  methods: {
    async goNext() {
      this.showQuestions = false;
      this.step += 1;
      await this.$nextTick();
      this.showQuestions = true;
    },
    finish() {
      const result = TEST_RESULT_TYPES.reduce((acc, el) => {
        acc[el] = 0;
        return acc;
      }, {});

      this.questions.forEach((el) => {
        result[el.value] += 1;
      });

      this.type = this.getMinimalName(result);
      this.$bvModal.show('test-result');
    },
    getMinimalName(obj) {
      const min = Math.min(...Object.values(obj));

      for(const prop in obj) {
        if (obj[prop] === min) {
          return prop;
        }
      }
    }
  },
  created() {
    this.questions = TEST_QUESTIONS.map(el => ({
      question: el.question,
      answers: el.answers,
      value: '',
    }));
  },
};
</script>

<style scoped lang="scss">
	.content-container {
		background-color: #F5F5F5;
	}
  .test {
    min-height: 100vh;
    margin-bottom: 104px;

    h1 {
      margin-bottom: 47px;
    }
  }
  .test__question {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    text-transform: uppercase;
    color: $dark-text;
    margin-bottom: 23px;
  }
  .test__steps {
    display: flex;
    align-items: flex-end;
    margin-bottom: 36px;

    p {
      font-size: 18px;
      font-weight: 700;
      line-height: 15px;
      color: #BABABA;
      letter-spacing: 0em;
      margin-bottom: 0;

      span {
        position: relative;
        font-size: 36px;
        font-weight: 700;
        line-height: 27px;
        color: $black;

        &:after
        {
          content: "";
          position: absolute;
          width: 44px;
          height: 4px;
          left: 0;
          bottom: -2px;
          background-color: $green;
        }
      }

    }
  }
  .test__questions {
    margin-bottom: 30px;
  }
  @media (max-width: 1150px) {
    .test {
      padding: 0 12px;
    }
  }
</style>
