<template>
  <div class="content-container">
    <div class="content">
      <div class="psychologist-opinion">
        <div class="psychologist-opinion__preview">
          <h1 class="title d-sm-none">
            Психологические особенности восприятия и при чем тут ванная комната
          </h1>
          <div class="psychologist-opinion__preview-author">
            <img src="@/assets/png/author-of-article.png" alt="">
            <div class="psychologist-opinion__preview-author-texts">
              <h4>
                Новоселова Елена Анатольевна
              </h4>
              <p>
                Психолог, Член Российской Психотерапевтической Лиги
              </p>
            </div>
          </div>
          <div class="psychologist-opinion__preview-text">
            <h1 class="title d-none d-sm-block">
              Психологические особенности восприятия и при чем тут ванная комната
            </h1>
            <p>
              <b>
                Бывало ли с вами, что после довольно тяжелого дня, вы заходите в ванную, чтобы помыть руки, и абсолютно нейтральный запах мыла может просто в секунду выводит из себя. А ведь вы пользовались им вот уже несколько недель совершенно машинально, не обращая на него никакого внимания. Вроде мелочь, но последняя капля, способная довести до разбитого вдребезги зеркала или слез. Но не спешите думать, что с вами что-то не так.
              </b>
            </p>
            <p class="d-none d-md-block">
              В мозг человека постоянно поступает информация через пять основных органов чувств. Визуальный образ реальности передают рецепторы и нейро-медиаторы органов глаза, звуки, тональности, вибрации мы ощущаем с помощью органов слуха, через рецепторы, расположенные на коже ощущаем прикосновения, температуру, движение воздуха, вкусы и запахи распознаем через соответствующие органы.
            </p>
          </div>
        </div>
        <div class="psychologist-opinion__content">
          <p class="d-md-none">
            В мозг человека постоянно поступает информация через пять основных органов чувств. Визуальный образ реальности передают рецепторы и нейро-медиаторы органов глаза, звуки, тональности, вибрации мы ощущаем с помощью органов слуха, через рецепторы, расположенные на коже ощущаем прикосновения, температуру, движение воздуха, вкусы и запахи распознаем через соответствующие органы.
          </p>
          <p>
            Однако, распознавание окружающей среды и выбор оптимального способа взаимодействия у людей разные. Реакция органов чувств на события окружающего мира во многом зависит от того, какой из каналов восприятия является ведущим.
          </p>
          <p>
            &nbsp;Это во многом зависит от географического места рождения и генетически обусловлено. Так, например, люди, родившиеся в северных широтах или имеющие там генетические корни, больше склонны воспринимать окружающий мир через ощущения рецепторов кожи. Эволюционно на севере выживание и удовлетворение первичных потребностей в еде, воде и тепле было решающим. Отсюда и предпочтение ощущениям кожного покрова, являющимся сигналом либо угрозы, либо сообщающим мозгу информацию, что можно расслабиться и не концентрировать свое внимание на этом аспекте окружающей среды.
          </p>
          <p>
            Для человека, связанного с южным климатом и географическом расположении места рождения, характерен акцент на визуальное восприятие мира. Яркая природа, изобилие разноцветных плодов, цвет моря, неба, гор- все это красочное разнообразие сообщает человеку не только информацию о красоте природы. Наблюдение за изменением оттенков неба, гор и прочего предупреждает об опасности или говорит о благополучии. Уроженцы юга интенсивно жестикулируют, энергично разговаривают, поскольку торопятся передать собеседнику как можно больше информации и при этом активно дорисовывают меняющиеся картинки жестами. Ошибочно такую манеру принимают за высокий темперамент. А это работает система приема и кодирования информации.
          </p>
          <b-collapse id="psychologist-opinion-hidden">
            <p>
              То же самое и с аудиалами. Чаще всего происхождение аудиального восприятия мира связано с местами, насыщенными лесными массивами. От того, как шумит лес, какие звуки там раздаются, зависит и безопасность, и благополучие.
            </p>
            <p>
              &nbsp;Эти примеры частично иллюстрируют одну из причин возникновения ведущего канала восприятия, ее эволюционно обусловленную необходимость.
            </p>
            <p>
              Отдельно следует сказать о дигитальной системе, поскольку она напрямую не связана с каким-либо отдельным органом чувств. А также ее формирование не может быть аттребутировано по географическому принципу. Люди этого типа являются представителями логического типа личности, руководствующиеся, в основном, выстраиванием причинно-следственных цепочек рассуждения и логически выверенными решениями. Положительные эмоции и ощущения расслабленности у них возникает лишь от определения сознанием структурности и упорядоченности полученной извне информации. Таких людей на Земле очень мало, исследования говорят о 1-5 процентах.
            </p>
            <p>
              Несмотря на то, что органов восприятия у нас пять, определены четыре основных способа восприятия как ведущих. Вкусовые рецепторы не могут являть собой отдельную систему, поскольку касаются только поглощения человеком еды или жидкости.
              <br>
              Итак, мы говорим о четырех каналах взаимодействия с окружающим миром, которые в психологии называются репрезентативными системами. Визуальный, кинестетический, аудиальный, дигатальный.
              <br>
              Репрезентативная система – это функция  восприятия и переработки информации человеком.
            </p>
            <p>
              Конечно же, каждый здоровый человек видит, слышит, чувствует и размышляет. Каждый задействует все пять органов чувств, но ведущей системой является одна, к которой ситуативно может присоединяться как вспомогательная ведущая любая из перечисленных.
              <br>
              Соответственно ведущей системе восприятия носителям разных репрезентативных систем дали категориальные наименования.
              <br>
              &nbsp;Визуальный тип репрезентативной системы – это человек, воспринимающий мир глазами в большей степени, чем другими органами чувств, создающий зрительные образы и запоминающие информацию через них. Таких примерно 60% - информация воспринимается и запоминается ими преимущественно через зрительные образы.
              <br>
              Определить визуала можно по следующим признакам:
              <br>
              - речь энергична, сопровождается активной жестикуляцией.
              <br>
              - в словарном активе чаще используются глаголы и предикаты, относящиеся к визуальному ряду: «Мне это видится так…», «Я вижу смысл в Вашем предложении», «Посмотрите, как удобно…»
              <br>
              - в одежде визуала Вы непременно обнаружите детали, которые требуют периодического обращения к зеркалу: поправить шейный платок, галстук, воротник.
              <br>
              - женщина предпочтет высокий каблук, даже если это будет физически не очень удобно. А мужчина узкие туфли кроссовкам.
              <br>
              - предпочтение «как это смотрится» превалирует над «насколько это удобно»
              <br>
              Для аудиала характерны следующие признаки:
              <br>
              &nbsp;Аудиалы склонны воспринимать и запоминать информацию через звуковые впечатления. Тональность и экспрессия голоса собеседника для них важнее, чем логика рассуждения. Именно звуковые вибрации вызовут наибольшие эмоции, а значит и определят отношение к тому или иному объекту.
              <br>
              Принято считать, что таких людей не более 10-15 процентов
              <br>
              Распознать аудиала можно по следующим признакам:
              <br>
              - во время коммуникации голова повернута в полупрофиль
              <br>
              - уходит от зрительного контакта
              <br>
              - богатая лексика и эмоциональное тонирование речи
              <br>
              - в разговоре использует следующие конструкции: "Звучит похожим на правду", «Это навевает приятные мысли» «Звучит убедительно»
              <br>
              - во внешнем облике и в одежде ничем особенным не выделяется
            </p>
            <p>
              Кинестетический тип личности – человек, воспринимающий мир через ощущения. Для него состояние телесного комфорта, ощущения кожи, запахов, температура окружающей среды являются решающими в определении реакций- расслабляться или готовиться к напряжению. Таких людей не менее 30 процентов. Очень часто кинестетическая репрезентативная система сочетается с визуальной, ситуативно замещая одна другую.
              <br>
              Определить кинестетика можно по следующим признакам:
              <br>
              - тело расслаблено, сообщает зрителю об удобстве
              <br>
              - при жестикуляции руки расположены в основном внизу
              <br>
              - стремится дотронуться до поверхности того или иного предмета, как бы исследую окружающий мир через ощущения пальцев рук
              <br>
              - в одежде предпочитают удобство, всегда одеты по погоде
              <br>
              - речь замедленная и с паузами, при этом они могут крутить что-нибудь в  руках
              <br>
              - говорят содержательно и вдумчиво
              <br>
              - в разговоре используют конструкции: "Я чувствую, что вы правы", "В ваших словах ощущается истина), «Чувствую, это понравится моей жене»
              <br>
              Носитель дигитальной системы восприятия – чистый логик. Информация поступает к нему, как и ко всем людям, через все органы чувств. Но ведущей системы через чувства у него нет. Он все пропускает через механизм выстраивания логических и причинно-следственных связей. Даже понятия эстетической привлекательности обязательно пройдут этот фильтр сознания.
              <br>
              Сколь красивой ни была бы картина, но если там присутствует деталь, нарушающая логический строй, дигитал не испытает приятных эмоций,
              <br>
              Распознать дигитала можно лишь по принципу рациональности, главенствующему в его одежде и в процессе беседы.
              <br>
              Как следует из разбора особенностей каждой репрезентативной системы, они играют важнейшую роль в нашей жизни, определяя настроение, выбор, ощущения счастья или его отсутствия. Это касается всех аспектов жизни человека: от самых возвышенных идей и планов до самых незначительных бытовых вещей.
              <br>
              И. конечно же, репрезентативные системы играют решающую роль в вопросах обустройства дома, интерьера, мест, где человек предполагает укрыться от бурной жизни и почувствовать себя расслабленным и самим собой.
              <br>
              Тем более это касается обустройства пространства ванной комнаты, самого интимного места нашего жилища.
              <br>
              <b>
                Именно в ванной мы остаемся наедине с собой, «снимаем маску», которую носили весь день. Как только вы перестаете себя контролировать, чувства обостряются.
                <br>
                Мы привыкли думать, что место отдыха – это спальня, возможно, гостиная. Лишь немногие используют ванную как пространство для релаксации, принимая ванны, практикуя растирания, самомассаж или уходовые процедуры. И совсем единицы задумываются о том, что само пространство ванной: то как оно организовано, как освещено, как оборудовано, как выглядит может быть фактором стресса или, напротив, стать тихой гаванью, в которой набираешься энергии на день и «смываешь» его, придя домой.
                <br>
                Знание своей ведущей репрезентативной системы поможет минимизировать факторы стресса в существующем обустройстве ванной комнаты или полностью исключить их на этапе проектирования и ремонта.
              </b>
            </p>
          </b-collapse>
          <div v-b-toggle="'psychologist-opinion-hidden'" class="psychologist-opinion__show-more button-link">
            <span>
              Читать далее
            </span>
            <span>
              Скрыть
            </span>
            <span>&gt;</span>
          </div>
          <div class="psychologist-opinion__test-btn">
            <div class="button">
              Узнай свой тип!
            </div>
            <router-link to="/test" class="button button-green">
              пройти тест
            </router-link>
          </div>
          <div class="psychologist-opinion__recomendation" id="recommendations">
            <h2 class="title">
              РЕКОМЕНДАЦИИ по типу репрезентативной системы
            </h2>
            <div class="row">
              <div
                v-for="recommendation, type in recommendations"
                :key="type"
                :id="type"
                class="col-12 d-flex flex-column mb-sm-4 col-sm-6"
              >
                <div class="psychologist-opinion__recomendation-content">
                  <h3>
                    {{ recommendation.title }}
                  </h3>
                  <p>
                    {{ recommendation.text }}
                  </p>
                </div>
                <b-carousel controls :interval="10000000">
                  <b-carousel-slide v-for="slide, slideIdx in recommendation.slides" :key="slideIdx">
                    <img :src="require(`@/assets/png/${slide.img}.png`)" alt="">
                    <h5>
                      {{ slide.title }}
                    </h5>
                    <p>
                      {{ slide.text }}
                    </p>
                  </b-carousel-slide>
                </b-carousel>
              </div>
            </div>
          </div>
          <a
            href="https://leroymerlin.ru/uslugi/proektirovanie-i-dizayn/sozdayte-vannuyu-komnatu-svoey-mechty/"
            target="_blank"
            class="button button-green ml-auto mr-auto psychologist-opinion__end-link"
          >
            спроектировать свою ванную комнату
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RECOMMENDATIONS_BY_TYPE } from '@/consts/test.js';
export default {
  name: 'PsychologistOpinion',
  data() {
    return {
      recommendations: RECOMMENDATIONS_BY_TYPE
    };
  }
};
</script>

<style scoped lang="scss">
  .content-container {
    background-color: #F5F5F5;
  }
  .psychologist-opinion {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $dark-text;

    b {
      font-weight: 500;
    }

    h3 {
      font-weight: 700;
      font-size: 24px;
      line-height: 48px;
    }

    h5 {
      font-style: italic;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 8px;
    }

    a:not(.button) {
      color: $green;
    }

    .title {
      padding-top: 20px;
      margin-bottom: 30px;
    }
  }
  .psychologist-opinion__content {
    margin-bottom: 66px;

    p {
      margin-bottom: 20px;
    }
  }
  .psychologist-opinion__preview {
    display: flex;

    .title {
      font-size: 36px;
      line-height: 42px;
    }

    p {
      margin-bottom: 20px;
    }
  }
  .psychologist-opinion__preview-author {
    position: relative;
    height: 328px;
    width: 219px;
    min-width: 219px;
    margin-right: 64px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .psychologist-opinion__preview-author-texts {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    left: 0;
    bottom: 0;
    min-height: 50%;
    width: 100%;
    padding: 10px;
    background: linear-gradient(0deg, #313131 0%, rgba(49, 49, 49, 0.0104167) 99.99%, rgba(90, 176, 49, 0) 100%);
    color: $white;

    h4 {
      font-weight: 700;
      font-size: 21px;
      line-height: 25px;
      text-transform: uppercase;
      margin-bottom: 3px;
    }

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 0;
    }
  }
  .psychologist-opinion__test-btn {
    display: flex;
    justify-content: center;
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.1));
    margin-bottom: 66px;

    & > div {
      background-color: $white;
      color: $dark-text;
      cursor: default;
    }
  }
  .psychologist-opinion__recomendation {
    .title {
      margin-bottom: 43px;
    }
  }
  .psychologist-opinion__recomendation {
    h3 {
      color: $green;
      margin-bottom: 7px;
    }

    p {
      font-size: 14px;
      line-height: 18px;
    }

    img {
      height: 270px;
      width: 100%;
      object-fit: cover;
      margin-bottom: 18px;
    }

    &::v-deep(.carousel-caption) {
      position: static;
      color: $dark-text;
      text-align: left;
      padding-top: 0;
    }

    &::v-deep(.carousel-control-prev), &::v-deep(.carousel-control-next) {
      background-color: $green;
      height: 27px;
      width: 27px;
      border-radius: 50%;
      background-image: url(@/assets/svg/icons/down-arrow.svg);
      background-repeat: no-repeat;
      background-position: center center;
      top: 130px;
      opacity: 1;
      margin-left: 12px;
      margin-right: 12px;

      .carousel-control-prev-icon, .carousel-control-next-icon {
        display: none;
      }

      &:hover {
        opacity: .86;
      }
    }

    &::v-deep(.carousel-control-prev) {
      transform: rotate(90deg);
    }
    &::v-deep(.carousel-control-next) {
      transform: rotate(-90deg);
    }
  }
  .psychologist-opinion__recomendation-content {
    min-height: 260px;
  }
  .psychologist-opinion__show-more {
    position: relative;
    display: inline-block;

    &.not-collapsed {
      span:nth-child(1) {
        display: none;
      }
      span:nth-child(2) {
        display: inline-block;
      }

      span:nth-child(3) {
        transform: rotate(-90deg);
      }
    }

    span:nth-child(2) {
      display: none;
    }

    span:nth-child(3) {
      position: absolute;
      transform: rotate(90deg);
      right: -17px;
      transition: .2s ease;
    }
  }
  @media (max-width: 999px) {
    .psychologist-opinion {
      padding-top: 110px;
    }
  }
  @media (max-width: 999px) {
    .psychologist-opinion {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  @media (max-width: 800px) {
    .psychologist-opinion__recomendation-content {
      min-height: 280px;
    }
  }
  @media (max-width: 768px) {
    .psychologist-opinion__preview {
      .title {
        font-size: 26px;
        line-height: 30px;
        padding-top: 0;
        margin-bottom: 20px;
      }
    }
    .psychologist-opinion__preview-author {
      margin-right: 10px;
      margin-bottom: 28px;
    }
    .psychologist-opinion__recomendation-content {
      min-height: 310px;
    }
    .psychologist-opinion__test-btn {
      margin-top: 22px;
      margin-bottom: 50px;

      .button {
        font-size: 21px;
        padding: 20px 30px;
        white-space: nowrap;
      }
    }

    .psychologist-opinion__end-link {
      white-space: nowrap;
      padding: 15px 25px;
      font-size: 18px;
    }
  }
  @media (max-width: 575px) {
    .psychologist-opinion {
      font-size: 14px;
      line-height: 18px;
    }

    .psychologist-opinion__preview {
      display: block;
    }

    .psychologist-opinion__preview-author {
      height: 328px;
      width: 100%;
      margin-right: 0;

      img {
        object-position: 0 20%;
      }
    }

    .psychologist-opinion__preview-author-texts {
      h4 {
        font-size: 24px;
        line-height: 25px;
      }

      p {
        width: 60%;
      }
    }

    .psychologist-opinion__recomendation-content {
      min-height: unset;
    }

    .psychologist-opinion__recomendation {

      .title {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 18px;
      }

      img {
        height: auto;
      }

      p {
        font-size: 12px;
        line-height: 16px;
      }

      &::v-deep(.carousel-caption) {
        padding-bottom: 30px;
      }

      &::v-deep(.carousel-control-prev), &::v-deep(.carousel-control-next) {
        top: 100px;
      }
    }

    .psychologist-opinion__show-more {
      font-size: 21px;
    }
  }
  @media (max-width: 420px) {
    .psychologist-opinion {
      padding-top: 36px;
    }
  }
</style>
