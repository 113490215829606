<template>
  <div>
    <b-modal
      id="popit-win-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      centered
    >
      <span class="close-icon"
            @click="$bvModal.hide('popit-win-modal')">
        <img src="@/assets/svg/icons/close.svg"
             aria-label="close">
      </span>

      <div class="d-flex flex-column align-items-center">
        <h1 class="title">Поздравляем, вы прошли игру!</h1>

        <p class="modal-text">
          Хотите спроектировать такую же <br>
          ванную комнату? <br>
          Переходите по ссылке
        </p>

        <a target="_blank"
           href="https://leroymerlin.ru/uslugi/proektirovanie-i-dizayn/sozdayte-vannuyu-komnatu-svoey-mechty/"
           class="button button-green">
          спроектировать ванную комнату
        </a>

        <div class="d-flex align-items-center">
          <button class="button-link">Выбрать другой дизайн</button>

          <button class="button-link">начать заново</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
  export default {
    data() {
      return {
      }
    },
  }
</script>

<style scoped lang="scss">
.title {
  text-align: center;
  margin-top: 85px;

  @media(max-width: 576px){
    margin-top: 50px;
  }
}

.close-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  margin-top: 5px;
  cursor: pointer;
  transition: .5s all;

  &:hover {
    opacity: .8;
  }

  @media(max-width: 576px){
    margin-top: -13px !important;
    margin-right: -5px;
  }
}

.modal-text {
  font-size: 24px;
  font-weight: 500 !important;
}

.button {
  width: 100%;
  max-width: 350px;
  margin-top: 30px;

  @media(max-width: 576px){
    margin-top: 10px;
  }
}

.button-link {
  margin-top: 15px;
  margin-right: 30px;
  margin-bottom: 60px;

  &:last-child {
    margin-right: 0;
  }

  @media(max-width: 576px){
    margin-right: 15px;
  }
}
</style>
