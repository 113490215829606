import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Vuelidate from 'vuelidate'
import VueTheMask from 'vue-the-mask'
import { BootstrapVue } from 'bootstrap-vue'
import VueScrollTo from 'vue-scrollto'
import VueDebounce from 'vue-debounce'

import '@/styles/scss/_templates.scss'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(Vuelidate)
Vue.use(VueTheMask)
Vue.use(VueScrollTo)
Vue.use(BootstrapVue)
Vue.use(VueDebounce)

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_API_URL

Vue.prototype.$http = axios

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
